<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="agree">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">Crear fórmula</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">
          <h2 class="primary--text mb-1">Normas generales</h2>
          <div class="ms-4">Para hacer cálculos con el precio del artículo, utiliza "p"</div>
          <div class="ms-4 primary--text">p = 22; (p*0.33)/8 = 0.9</div>
          <div class="ms-4">El cero en la parte entera de un numero decimal no es necesario</div>
          <div class="ms-4 primary--text">22*0.33 = 22*.33</div>
          <h3 class="primary--text my-1">SUMA (+)</h3>
          <ul>
            <li><strong>Positivos:</strong> 2<span class="primary--text">+</span>7</li>
            <li><strong>Negativos:</strong> 2<span class="primary--text">+</span>-2</li>
            <li><strong>Decimales:</strong> 2<span class="primary--text">+</span>0.2 o 2<span class="primary--text">+</span>.2</li>
          </ul>
          <h3 class="primary--text my-1">RESTA (-)</h3>
          <ul>
            <li><strong>Positivos:</strong> 12<span class="primary--text">-</span>3</li>
            <li><strong>Negativos:</strong> 6<span class="primary--text">-</span>+1</li>
            <li><strong>Decimales:</strong> 8<span class="primary--text">-</span>0.14 o 8<span class="primary--text">-</span>.14</li>
          </ul>
          <h3 class="primary--text my-1">MULTIPLICACIÓN (*)</h3>
          <ul>
            <li><strong>Positivos:</strong> 4<span class="primary--text">*</span>2</li>
            <li><strong>Negativos:</strong> 5<span class="primary--text">*</span>-1</li>
            <li><strong>Decimales Positivos:</strong> 22<span class="primary--text">*</span>0.33 o 22<span class="primary--text">*</span>.33</li>
            <li><strong>Decimales Negativos:</strong> 22<span class="primary--text">*</span>-0.33 o 22<span class="primary--text">*</span>-.33</li>
          </ul>
          <h3 class="primary--text my-1">DIVISIÓN (/)</h3>
          <ul>
            <li><strong>Positivos:</strong> 42<span class="primary--text">/</span>8</li>
            <li><strong>Negativos:</strong> 23<span class="primary--text">/</span>-2</li>
            <li><strong>Decimales Positivos:</strong> 40<span class="primary--text">/</span>0.5 o 40<span class="primary--text">/</span>.5</li>
            <li><strong>Decimales Negativos:</strong> 40<span class="primary--text">/</span>-0.5 o 40<span class="primary--text">/</span>-.5</li>
          </ul>
          <h3 class="primary--text my-1">EJEMPLO</h3>
          <ul>
            <li><strong>Sea p = 22.3</strong></li>
            <li><strong>Hay una variación del 8% en el precio</strong></li>
            <li><strong>Una ración es el 33% de una unidad</strong></li>
            <li><strong>Cada ración es para 2 personas</strong></li>
            <li><strong>(p+(p*.08)*.33)/2</strong></li>
            <li><strong>Precio = 11.44</strong></li>
          </ul>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click.native="agree">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 490,
      zIndex: 200
    }
  }),
  methods: {
    open(options) {
      this.dialog = true
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
  }
}
</script>